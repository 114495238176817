import React from 'react'
import './Cost_analysis.css'
function Cost_analysis() {
    return (
        <div className="cost_analy_holder">
            <h1 className="cost_analy_title">Predictive analyses</h1>
            <p className="cost_graph_desc">*Machine learning models refreshed as of 12/1*</p>
            <div>
                <p className="cost_analy_text">Seasonal trend predicted: <span className="cost_analy_high1">True</span></p>
                <p className="cost_analy_text">Predicted seasonal impact: <span className="cost_analy_high1">+$2,014.81</span></p>
                <p className="cost_analy_text">Predicted EOY customers: <span className="cost_analy_high1">1,976</span></p>
                <p className="cost_analy_text">Predicted supply increase need: <span className="cost_analy_high1">True</span></p>
                <p className="cost_analy_text">Marketing impact: <span className="cost_analy_high1">+364 customers</span></p>
                <p className="cost_analy_text">Largest running cost: <span className="cost_analy_high1">Brew Supplies</span></p>
                <p className="cost_analy_text">Most volatile cost: <span className="cost_analy_high1">Employees</span></p>
                <p className="cost_analy_text">Current inventory usability: <span className="cost_analy_high1">5.2 months</span></p>
                <p className="cost_analy_text">Predicted next total cost: <span className="cost_analy_high1">$10,980.81</span></p>
                <p className="cost_analy_text">Industry favorability: <span className="cost_analy_high1">True</span></p>
                <p className="cost_analy_text">Best social media effect: <span className="cost_analy_high1">Instagram</span></p>
                <p className="cost_analy_text">New-hire staffing risk: <span className="cost_analy_high1">False</span></p>
            </div>


            
        </div>
    )
}

export default Cost_analysis
