import React from 'react'
import './Advanced_holder.css'
import Pi_chart from './Pi_chart'

function Advanced_holder() {
    return (
        <div className="advanced_holder">

            <Pi_chart/>

            
        </div>
    )
}

export default Advanced_holder
