import logo from './logo.svg';
import './App.css';
import Logo from './components/Logo'
import Main_Points from './components/main_points/Main_points'
import Consultation from './components/consultation/consultation'
import Trust_Process from './components/trust_process/Trust_process'
import Preview from './components/preview/Preview'
import Contact from './components/contact/Contact'
import Dash from './components/dashboard/Dash'
import TopMenu from './components/top_menu/TopMenu';
import React, {useState, useEffect} from "react";
import  { Redirect, BrowserRouter as Router, Route, Switch, useHistory, useCallback } from 'react-router-dom'

function App() {

  console.log("Start of Mobile")
  // Global Variables
  const history = useHistory()
  //const teamHandleClick = () => history.push(`/team`);//useCallback(() => history.push('/team'), [history]);


  // Event listener for parallax scrolling
  const [offsetY, setOffsetY] = useState();
  const handleScroll = () => setOffsetY(window.pageYOffset)

 // Globalize listener
  useEffect(()=> {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener('scroll',handleScroll);
      }, [])



    // Check for mobile device
    if (window.screen.width > 1000) {
        window.location.assign("https://www.axm.ai/")
     }



  return (
    <Router>
    <div className="App">

        <Switch>
            <Route exact path="/">
                <TopMenu />
                <Logo/>
                <Main_Points scroller={offsetY} />
                <Consultation/>
                <Preview />
                <Trust_Process/>
                <Contact />
            </Route>

            <Route exact path='/preview'>
              <Dash history={history} />
            </Route>


        </Switch>

    </div>
    </Router>
  );
}

export default App;
