import React from 'react'
import './Contact.css'
import phone from '../top_menu/phone.png'
import email from '../top_menu/email.png'

function Contact() {

    const [name_value, setNameValue] = React.useState('');
    const [company_value, setCompanyValue] = React.useState('');
    const [email_value, setEmailValue] = React.useState('');
    const [callback_value, setCallbackValue] = React.useState('');

    const [mediashow, setMediashow] = React.useState(false);


    const handleNameChange = (event) => {
        setNameValue(event.target.value)
    }

    const handleCompanyChange = (event) => {
        setCompanyValue(event.target.value)
    }

    const handleEmailChange = (event) => {
        setEmailValue(event.target.value)
    }

    const handleCallbackChange = (event) => {
        setCallbackValue(event.target.value)
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        document.getElementById('button_text_submit').innerText="submitted!\n talk to you soon!"
        document.getElementById('button_text_submit').setAttribute('style',`position: relative; top: -12px; color: black;`)
        document.getElementById('form_submit_button').setAttribute('style','background-color:var(--ax-green)')
        console.log(`
        Nice submit, here's the results:
        Name: ${name_value},
        Company: ${company_value},
        Email: ${email_value},
        Callback: ${callback_value}
        `)
    }


    return (
        <div>
            <h1 className="title">Learn more today!</h1>

            <div className="form_holder">
            <p className="contact_subtext">Reach out for a free consultation today... Accelerate your business and save tomorrow's time, today.</p>
            <br></br>


                    <div className='contact_holders'>
                            <a className="form_subtext" href='mailto:axm.analytics@gmail.com'>
                                <img className='contact_img' src={email} />
                                {/* <p className='contact_text'>Email</p> */}
                            </a>


                            <a className="form_subtext" href="tel:860-597-7110">
                                <img className='contact_img' src={phone} />
                                {/* <p className='contact_text'>Phone</p> */}
                            </a>
                    </div>

            </div>

            <br></br>

            <div className="breaker"></div>
            
        </div>
    )
}

export default Contact
