import React from 'react'
import './Pi_chart.css'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';


const data01 = [
  { name: 'Food', value: 400 },
  { name: 'Dine-in Beer', value: 300 },
  { name: 'Purchased Beer', value: 300 },
  { name: 'Merchandise', value: 200 },
];
const data02 = [
  { name: 'Dessert', value: 100 },
  { name: 'Entree', value: 300 },
  { name: 'IPA/IIPA', value: 100 },
  { name: 'Lager', value: 80 },
  { name: 'Pilsner', value: 40 },
  { name: 'Sour', value: 30 },
  { name: 'B5', value: 50 },
  { name: 'Other', value: 100 },
  { name: 'IPA/IIPA', value: 200 },
  { name: 'Clothes', value: 150 },
  { name: 'Items', value: 50 },
];


const COLORS = ['#00EEBB', '#FF00C7', '#7F00FF', '#393939'];
//   --ax-green:#00EEBB;  #24f1c5  #49f3ce #6ff5d8; #99f3df
//   --ax-pink:#FF00C7;  #fd2bcf #f74bd1  #fd74df  #fb96e5
//   --ax-purple:#7F00FF; #8a1bfb #8a1bfb #9c3bff #ae5eff
//   --ax-grey:#393939;  #484848 #696969 #8a8a8a #afafaf

const COLORS2 = [  '#00EEBB', '#24f1c5', 
                  '#FF00C7', '#fd2bcf', '#f74bd1', '#fd74df', '#fb96e5',
                '#7F00FF','#8a1bfb',
                '#393939','#484848']

let renderLabel = function(entry) {
return `${entry.name}`;
}

function Pi_chart() {



    return (
        <div>
              <p className="pi_subtext">Sales breakdown</p> 
              <p className="cost_graph_desc">*Outer rim is subcategories... Click to view details*</p>

                      <div className='pi_row'>

                      {/* <div className="pi_legend">

<p className="pi_legend_text_g">food</p>
<p className="pi_legend_text_p">dine-in beer</p>
<p className="pi_legend_text_pr">purchased beer</p>
<p className="pi_legend_textgy">merchandise</p>

</div> */}


                                <PieChart width={370} height={370}>
                                  <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={70}>

                                                      {data01.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}

                                    </Pie>
                                    
                                    <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={90} outerRadius={110} fill="#000a9d" label={renderLabel} >
                                                      {data02.map((entry, index) => (
                                                              <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                                                              ))}
                                      </Pie>


                                  <Tooltip />
                                </PieChart>










                      </div>
            
        </div>
    )
}

export default Pi_chart
