import React from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import './KPI_two.css'



function KPI_three() {
    const data = [
        {name: "March", Costs:5147},
        {name: "April", Costs:5347},
        {name: "May", Costs:4347},
        {name: "June", Costs:4736},
        {name: "July", Costs:2512},
        {name: "Aug", Costs:1936},
        {name: "Sept", Costs:1738}
    ]
    return (
        <div className="kpi_holder">
            <h1 className="kpi_three_subtext">Costs trending</h1>
            <p className="cost_graph_desc">*Includes fixed costs from 3/1-9/31... Click to view more*</p>
            <LineChart width={370} height={200} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <Line type="monotone" dataKey="Costs" stroke="#FF00C7" />
                <XAxis dataKey="name" stroke="#EEE0FF" />
                <YAxis stroke="#EEE0FF" />
                <Tooltip             
                        wrapperStyle={{ backgroundColor: "black" }}
                        labelStyle={{ color: "black" }}
                        itemStyle={{ color: "purple" }}
                        formatter={function(value, name) {
                        return `$${value}`;
                        }}
                        labelFormatter={function(value) {
                        return `${value}`;
                        }}
             />
            </LineChart>
      </div>
    )
}

export default KPI_three
