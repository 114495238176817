import React from 'react'
import './Trust_process.css'
import Trust_Graphic from './Trust_Graphic'

function Trust_process() {
    return (
        <div className="trust_holder">

            <h1 className="title">Trust the process.</h1>
            <p className="subtext">By leveraging strategies used in fortune 500 companies, you can be sure that axm solutions create competitive advantages that are built to last.</p>
            {/* <Trust_Graphic /> */}
            <div className="trust_mobile_graphic"> 
                    <p className="trust_mobile_graphic_txt">Define problems</p>
                </div>
                <div className="trust_mobile_graphic"> 
                    <p className="trust_mobile_graphic_txt">Collect data</p>
                </div>
                <div className="trust_mobile_graphic"> 
                    <p className="trust_mobile_graphic_txt">Generate analytics</p>
                </div>
                <div className="trust_mobile_graphic"> 
                    <p className="trust_mobile_graphic_txt">Take action</p>
                </div>
            
            <div className="breaker"></div>
        </div>
    )
}

export default Trust_process
