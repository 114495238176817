import React from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import './KPI_one.css'



function KPI_one() {
    const data = [
        {name: "March", Sales:7121},
        {name: "April", Sales:9936},
        {name: "May", Sales:6682},
        {name: "June", Sales:8177},
        {name: "July", Sales:11177},
        {name: "Aug", Sales:3177},
        {name: "Sept", Sales:8177}
    ]
    return (
        <div className="kpi_holder">
            <h1 className="kpi_subtext">Sales Trending</h1>
            <p className="cost_graph_desc">*Total revenue from 3/1-9/31... Click to view more*</p>
            <LineChart width={370} height={150} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <Line type="monotone" dataKey="Sales" stroke="#00EEBB" />
                <XAxis dataKey="name" stroke="#EEE0FF" />
                <YAxis stroke="#EEE0FF" />
                <Tooltip             
                        wrapperStyle={{ backgroundColor: "black" }}
                        labelStyle={{ color: "black" }}
                        itemStyle={{ color: "green" }}
                        formatter={function(value, name) {
                        return `$${value}`;
                        }}
                        labelFormatter={function(value) {
                        return `${value}`;
                        }}
             />
            </LineChart>
      </div>
    )
}

export default KPI_one
